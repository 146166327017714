@import '../../assets/sass/styles.scss';

.Register {
  .hidden-desktop {
    display: none;
  }
  .hidden-mobile {
    display: initial;
  }
  #register-div {
    position: relative;
    z-index: 5;
    background-color: white;
  }
  .header {
    min-height: 120vh;
    // background-image: url('../../assets/images/bg_empty_no_flower.jpg');
    background-image: url('../../assets/images/background.jpg');
    background-color: #fed3db;
    background-position: top center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    .logo {
      padding-left: 0;
    }
    .slogan-container {
      z-index: 2;
      position: absolute;
      min-width: 100vw;
      max-height: 120vh;
    }
    .flowers {
      position: absolute;
      z-index: 3;
      &.flower-1 {
        width: 660px;
        right: 20%;
        top: -90px;
        // transform: rotateX(180deg);
      }
      &.flower-3 {
        width: 300px;
        top: 31%;
        right: -120px;
        transform: rotate(200deg);
      }
      &.flower-5 {
        width: 350px;
        bottom: 0;
        left: 17%;
        transform: rotateY(180deg);
      }
    }
    .columns {
      z-index: 3;
      &.container {
        max-width: 80vw;
      }
      .text-col {
        margin-left: 2vw;
        .logo-columns {
          margin-top: 12vh;
          // margin-left: 2vw;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          img {
            padding-right: 20px;
            max-height: 60px;
          }
        }
        .text {
          color: $primary;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          // margin-left: 2vw;
          margin-bottom: 25vh;
          margin-top: 3vh;
          h1 {
            font-size: 6rem;
            font-weight: 200;
            text-align: start;
            line-height: 1;
          }
          p {
            font-size: 1.8rem;
            text-align: start;
            font-weight: 200;
            &.description {
              max-width: 80%;
              padding-top: 30px;
            }
            &.period {
              padding: 30px 0;
              font-size: 1rem;
            }
            .megabold {
              align-items: start;
              display: flex;
              flex-direction: column;
              // justify-content: end;
              // margin-top: -2.05rem;
              line-height: 1;
              text-align: center;
              font-size: 3.8rem;
              .smaller {
                font-size: 2rem;
              }
            }
          }
        }
        .register-nav-btn {
          margin-top: 10px;
          background-color: $primary;
          padding: 15px 50px;
          font-size: 1.4rem;
          font-weight: 200;
          color: white;
          border-radius: 55px;
          border: none;
          width: fit-content;
          bottom: 22vh;
          position: absolute;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .image-col {
        margin-right: 0;
        .candy-box {
          margin-top: 49vh;
          right: 8vw;
          max-width: 360px;
          position: absolute;
        }
        .candy-wrapper {
          position: absolute;
          text-align: center;
          bottom: 2vh;
          right: -5vw;
          img {
            max-height: 280px;
          }
          p {
            line-height: 1;
            color: $primary;
            font-size: 2.4rem;
            font-weight: 200;
            span {
              display: block;
            }
          }
        }
      }
    }
  }
  .reg-form {
    display: flex;
    justify-content: center;
    padding: 60px 0;
    &.hidden {
      display: none;
    }

    .close-btn {
      z-index: 1;
      height: 60px;
      position: absolute;
      right: 20px;
      background: none;
      border: none;
      cursor: pointer;
      span {
        display: block;
        width: 60px;
        height: 1px;
        background-color: $primary;
        &.first {
          transform: rotate(45deg);
        }
        &.second {
          transform: rotate(-45deg);
        }
      }
    }

    form {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 2%;
      width: fit-content;
      &.hidden {
        display: none;
      }
      h2 {
        color: $primary;
        font-size: 3rem;
        font-weight: 200;
      }
      p {
        font-size: 1.2rem;
        font-weight: 200;
        color: $primary;
        margin-bottom: 15px;
        a {
          color: $primary;
          text-decoration: underline;
        }
      }
      .field {
        width: 30vw;
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type='number'] {
          appearance: textfield;
          -moz-appearance: textfield;
        }
        .input {
          color: $primary;
          font-size: 1.2rem;
          font-weight: 200;
          border: 1px solid $primary;
          border-radius: 28px;
          padding: 28px;
          background-color: white;
          &::-webkit-input-placeholder {
            /* Edge */
            color: $primary;
          }
          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $primary;
          }
          &::placeholder {
            color: $primary;
          }
        }
      }
      .checkbox {
        padding-left: 30px;
        margin: 1vh 0;
        font-size: 1.2rem;
        color: $primary;
        font-weight: 200;
        align-self: flex-start;
      }
      .btn {
        background-color: $primary;
        padding: 15px 50px;
        font-size: 1.4rem;
        font-weight: 200;
        color: white;
        border-radius: 55px;
        border: none;
        width: fit-content;
        margin-top: 20px;
      }
      .campaign-over {
        color: $primary;
        font-weight: 600;
        font-size: 1.4rem;
      }
      #rules-check {
        margin-right: 10px;
      }
    }
  }
  .reg-success {
    padding: 20vh 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    span {
      font-size: 3rem;
      padding: 10px 0;
    }
    a {
      background-color: $primary;
      padding: 15px 50px;
      font-size: 1.4rem;
      font-weight: 200;
      color: white;
      border-radius: 55px;
      border: none;
      width: fit-content;
      margin-top: 20px;
      display: block;
    }
    &.hidden {
      display: none;
    }
  }
}

// small screen
@media (min-width: 992px) and (max-width: 1440px) {
  .Register {
    .header {
      background-repeat: no-repeat;
      .slogan-container {
        // max-height: initial;
      }
      .flowers {
        position: absolute;
        z-index: 3;
        &.flower-1 {
          right: 10%;
          top: -130px;
          // transform: rotateX(180deg);
        }
        &.flower-3 {
          width: 200px;
          top: 235px;
          right: -80px;
          transform: rotate(200deg);
        }
        &.flower-5 {
          width: 270px;
          bottom: 12px;
          left: 20%;
          transform: rotateY(180deg);
        }
      }
      .columns {
        &.container {
          max-width: initial;
        }
        .text-col {
          margin-left: 2vw;
          .logo-columns {
            margin-left: 12vw;
            margin-top: 2vh;
          }
          .text {
            margin-left: 12vw;
            h1 {
              font-size: 3.8rem;
            }
            p {
              font-size: 1.4rem;
              padding-bottom: 15px;
              &.description {
                max-width: 90%;
              }
              .megabold {
                align-items: start;
                display: flex;
                flex-direction: column;
                // justify-content: end;
                // margin-top: -2.05rem;
                line-height: 1;
                text-align: center;
                font-size: 3rem;
                .smaller {
                  font-size: 1.6rem;
                }
              }
            }
            .register-nav-btn {
              font-size: 1.2rem;
            }
          }
        }
        .image-col {
          margin-right: 9vw;
          .candy-box {
            max-height: 330px;
            margin-top: 57vh;
            right: 16vw;
          }
          .candy-wrapper {
            right: 3vw;
            bottom: 15px;
            img {
              max-height: 260px;
            }
            p {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
    .reg-form {
      form {
        .field {
          width: 40vw;
        }
      }
    }
  }
}

// Mobile
@media (max-width: 992px) {
  .Register {
    #register-div {
      margin-top: -5px;
    }
    .hidden-desktop {
      display: initial;
    }
    .hidden-mobile {
      display: none;
    }
    .header {
      min-height: initial;
      min-height: 120vh;
      // background-image: url('../../assets/images/mobile_bg_empty.jpg');
      background-position: top right;
      background-size: auto 100%;
      background-repeat: no-repeat;
      .slogan-container {
        position: relative;
      }
      .flowers {
        position: absolute;
        z-index: 0;
        &.flower-1 {
          width: 310px;
          right: 0;
          top: -90px;
        }
        &.flower-3 {
          width: 50px;
          top: 53%;
          right: 0;
          transform: rotate(0);
        }
        &.flower-5 {
          width: 150px;
          bottom: 0;
          left: 2%;
        }
      }
      .columns {
        &.container {
          max-width: 90vw;
          position: absolute;
        }
        .text-col {
          .logo-columns {
            margin-top: 10vh;
            margin-left: 0;
            img {
              padding-right: 10px;
              max-height: 50px;
            }
          }
          .mobile-candybox-wrapper {
            width: 100%;
            display: flex;
            justify-content: end;
            margin-top: 6vh;
            .candy-box {
              max-height: 225px;
              margin-top: -60px;
            }
          }
          .text {
            margin-bottom: 0vh;
            margin-top: -1vh;
            margin-left: 0;
            max-width: 75%;
            h1 {
              font-size: 2.1rem;
            }
            p {
              font-size: 0.8rem;
              &.description {
                max-width: 90%;
                padding-top: 10px;
              }
              &.period {
                padding: 10px 0;
                font-size: 0.7rem;
              }
              .megabold {
                font-size: 1.4rem;
                .smaller {
                  font-size: 1rem;
                }
              }
            }
          }
          .register-nav-btn {
            margin-top: 10px;
            padding: 10px 20px;
            font-size: 0.9rem;
            bottom: auto;
          }
        }
        .image-col {
          display: flex;
          justify-content: center;
          padding: 0;
          .candy-box {
            margin-top: 0;
            max-width: 150px;
            position: absolute;
            bottom: 25%;
            right: 17vw;
          }
          .candy-wrapper {
            position: initial;
            margin-top: 100px;
            padding-bottom: 30px;
            img {
              max-height: 120px;
            }
            p {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
    .reg-form {
      padding: 20px 0;
      .close-btn {
        height: 30px;
        right: 10px;
        span {
          width: 30px;
        }
      }

      form {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 2%;
        width: fit-content;
        h2 {
          font-size: 1.6rem;
          padding-top: 40px;
        }
        p {
          font-size: 0.8rem;
        }
        .field {
          width: 88vw;
          .input {
            font-size: 0.8rem;
            border-radius: 28px;
            padding: 20px;
          }
        }
        .checkbox {
          font-size: 0.8rem;
        }
        .btn {
          padding: 10px 20px;
          font-size: 0.9rem;
          margin-bottom: 30px;
        }
        .campaign-over {
          color: $primary;
          font-weight: 600;
          font-size: 1.4rem;
        }
        #rules-check {
          margin-right: 10px;
        }
      }
    }
    .reg-success {
      span {
        font-size: 1.2rem;
      }
      a {
        padding: 10px 20px;
        font-size: 0.9rem;
      }
    }
  }
}
