@import '../../assets/sass/styles.scss';

.Winners {
  padding: 60px 0;
  h2 {
    font-size: 3rem;
    font-weight: 200;
    color: $primary;
  }
  ul {
    padding: 20px 0;
    li {
      color: $primary;
      font-size: 2.5rem;
      font-weight: 200;
    }
  }
  img {
    padding-top: 40px;
    max-width: 10vw;
  }
}

@media (max-width: 992px) {
  .Winners {
    padding: 60px 0;
    h2 {
      font-size: 1.2rem;
    }
    ul {
      padding: 20px 0;
      li {
        font-size: 1.1rem;
      }
    }
    img {
      padding-top: 40px;
      max-width: 30vw;
    }
  }
}
