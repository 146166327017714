@import '../../assets/sass/styles.scss';

.Rules {
  background-color: #fed3db;
  // padding: 0 80px 80px 80px;
  .button-div {
    padding: 120px 0;
    .rules-nav-btn {
      background-color: $primary;
      margin-top: 60px;
      padding: 15px 50px;
      font-size: 1.4rem;
      font-weight: 200;
      color: white;
      border-radius: 55px;
      border: none;
      width: fit-content;
    }
  }
  .rules-container {
    background-color: #fed3db;
    padding: 80px 120px;
    &.hidden {
      display: none;
    }
    .close-btn {
      z-index: 1;
      height: 60px;
      position: absolute;
      right: 20px;
      background: none;
      border: none;
      cursor: pointer;
      span {
        display: block;
        width: 60px;
        height: 1px;
        background-color: $primary;
        &.first {
          transform: rotate(45deg);
        }
        &.second {
          transform: rotate(-45deg);
        }
      }
    }
    .rules-wrapper {
      text-align: start;
      color: $primary;
      font-size: 1.2rem;
      font-weight: 200;
      height: 500px;
      overflow-y: scroll;

      h2 {
        color: $primary;
        font-size: 3rem;
        font-weight: 200;
        text-align: center;
        padding-bottom: 30px;
        padding-top: 60px;
      }
      ol {
        list-style: decimal inside;
        padding: 20px;
        // counter-reset: item;
        // > li {
        //   counter-increment: item;
        //   display: table;
        //   &:before {
        //     color: $primary;
        //     font-weight: 200;
        //     padding-right: 10px;
        //     content: counters(item, '.') '.';
        //     display: table-cell;
        //   }
        // }
        p {
          display: block;
          padding-top: 10px;
        }
        li {
          margin: 10px 0;
        }
        ol,
        ul {
          padding-left: 20px;
        }
        .bold {
          font-weight: 600;
        }
        table {
          border-collapse: collapse;
          td,
          th {
            padding: 3px;
          }
        }
        table,
        th,
        td {
          border: 1px solid black;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .Rules {
    background-color: #fed3db;
    .button-div {
      .rules-nav-btn {
        margin-top: 60px;
        padding: 10px 20px;
        font-size: 0.9rem;
      }
    }

    .rules-container {
      padding: 40px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .close-btn {
        height: 30px;
        right: 10px;
        margin-top: -30px;
        span {
          width: 30px;
        }
      }
      .rules-wrapper {
        font-size: 0.8rem;
        max-width: 90vw;

        h2 {
          font-size: 1.2rem;
          padding-bottom: 30px;
        }
      }
    }
  }
}
