@import '../../assets/sass/styles.scss';
.Main {
  font-family: Gotham;
  #postcard-section {
    position: relative;
    z-index: 5;
  }
}
@media (min-width: 992px) and (max-width: 1440px) {
  // .Main {
  //   #postcard-section {
  //     margin-top: -5px;
  //   }
  // }
}
@media (max-width: 992px) {
  .Main {
    #postcard-section {
      margin-top: -6px;
    }
  }
}
